import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ILogin } from '../interfaces/ilogin';
import { CryptoSharkService } from '../shared/services/crypto-shark.service';
import { HttpService } from '../shared/services/http.service';
import { environment } from '../../environments/environment'; 
import { UserService } from '../shared/services/user.service';
import { Observable } from 'rxjs';
import { SocketService } from '../shared/services/socket.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  @ViewChild('modalCredentials') private modalCredentials: TemplateRef<any>;

  public loginForm: FormGroup;
  public submitted: boolean = false;
  public loginData: ILogin = {};
  public returnUrl: string
  public expiredSesionAlert: boolean;
  public invalidCredentials: boolean;

  public get = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.urlAdmin}${url}`, contentType ? { headers: { "Content-Type": contentType } } : {});
  
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private cryptoService: CryptoSharkService,
    private userService: UserService,
    private http: HttpClient,
    private socket: SocketService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.expiredSesionAlert = this.route.snapshot.queryParams['returnUrl'] ? true : false 
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/tickets'
    this.getSubdomain();
    
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      nip: ['', Validators.required],
    });
  }
  
  get loginFormControl() {
    return this.loginForm.controls;
  }

  /**
   * Obtener el dominio del cliente
   * @returns Dominio del cliente
   */
  getSubdomain() {
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www' ||
      domain.split('.')[0] === '45'
    ) {
      // environment.subdomain = 'demo'; 
      environment.subdomain = 'demos-services'; //pruebas
    } else {
      environment.subdomain = domain.split('.')[0];
    }
  }

  Submit(){ 
    this.get(`api/login/company/${environment.subdomain}`).subscribe((response) => {
        this.loginData.company = response.data;
        
        environment.apiUrl = this.loginData.company.server;  // comentar en caso de ser subdominio localTos de igual forma en user.services
        environment.socketUrl = this.loginData.company.server;  // comentar en caso de ser subdominio localTos de igual forma en user.services
        if(this.loginForm.invalid){ return;}

        this.httpService.post(`api/login`, {
          
          data: this.loginData.company.data,
          key: this.loginData.company.key,
          user: this.cryptoService.encryptAes(
            this.loginForm.controls['username'].value,
            this.loginData.company.key
          ),
          password: this.cryptoService.encryptSha(
            this.loginForm.controls['password'].value
          ),
          nip: this.cryptoService.encryptSha(
            this.loginForm.controls['nip'].value
          ),
        }).toPromise()
        .then((response: any) => { 
          this.loginData.token = response.token;
          this.loginData.isRegister = response?.isRegistered || false;
          this.userService.signInCurrentUser(this.loginData);

          if (response?.isRegistered) {
            this.router.navigateByUrl(this.returnUrl);
          }else{
            this.openModal(this.modalCredentials, "md");
          }
          
        }).catch((e) => {
          this.invalidCredentials = e?.error?.message ? true : false;
          console.log(e);
        })
    });
  }

  private openModal(modal, size: string = 'xxl') {
    this.modalService.open(modal, { size: size, backdrop: 'static' });
  }

  public updateCredentials():void{
    this.loginData.isRegister = true;
    this.userService.signInCurrentUser(this.loginData);
    this.router.navigateByUrl(this.returnUrl);
  }
}

