import { TitleCasePipe } from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core'
import { SSL_OP_DONT_INSERT_EMPTY_FRAGMENTS } from 'constants';
import { UserService } from '../services/user.service';

@Pipe({
    name: 'status'
})

/**
 * Pipe para transformar status.
 * 
 * INSTRUCCIONES DE USO: 
 * 1) Importar PipesModule en el módulo donde quieras tranformar status
 * 2) Utilizar el pipe 'status' en el html de tu componente
 */

export class statusPipe implements PipeTransform {

    public lenguages = {
        "DEFAULT":{
            DEFAULT: 'Sin Clasificación',
            NONE: 'Ninguno',
            NEW: this.titlecase.transform((this.userService.modulesName?.new || 'Nuevo')),
            ANALISIS: this.titlecase.transform((this.userService.modulesName?.analisis || 'Análisis')),
            AUTH_PEN_ADM:  this.titlecase.transform((this.userService.modulesName?.auth_pen_adm || 'Pend. Autorizar Admin')),
            AUTH_PEN_CLI: 'Pend. Autorizar Cliente',
            AUTORIZADO: 'Autorizado',
            TRABAJANDO: this.titlecase.transform((this.userService.modulesName?.trabajando || 'Trabajando')),
            LIB_PEN_ADM: this.titlecase.transform((this.userService.modulesName?.lib_pen_adm || 'Pend. Liberar Admin')),
            LIB_PEN_CLI: this.titlecase.transform((this.userService.modulesName?.lib_pen_cli || 'Pend. Liberar Cliente')),
            LIBERADO: this.titlecase.transform((this.userService.modulesName?.liberado || 'Finalizado')),
            CERRADO: 'Cerrado',
            RECHAZADO: 'Rechazado',
            LIB_REJ_CLI:"Rechazado cliente",
            CANCELLED:  this.titlecase.transform((this.userService.modulesName?.cancelado || 'Cancelado')),
            EXPIRE: 'Expirado',
            TICKET: this.titlecase.transform((this.userService.modulesName?.ticket || 'Ticket')),
            TICKETS: this.titlecase.transform((this.userService.modulesName?.ticket || 'Tickets')),
            PROYECTO: (this.userService.modulesName?.proyecto || 'Proyecto'),
            PROYECTOS: (this.userService.modulesName?.proyecto || 'Proyectos'),
            PROYECTOS2: this.titlecase.transform((this.userService.modulesName?.proyecto2 || 'Proyectos')),
            PROYECTO2: this.titlecase.transform((this.userService.modulesName?.proyecto2 || 'Proyectos')),
            OBRA: this.titlecase.transform((this.userService.modulesName?.obra || 'Obra')),
            OBRAS: this.titlecase.transform((this.userService.modulesName?.obra || 'Obras')),
            MANTENIMIENTO: this.titlecase.transform((this.userService.modulesName?.mantenimiento || 'Mantenimiento')),
            "PLANOS Y DOC.": this.titlecase.transform((this.userService.modulesName?.planos || 'Planos y Docs.')),
            _ASSIGNED: 'Asignado',
            ACCEPTED: 'Aceptado',
        
            DINERO: 'Dinero',
            TIEMPO: 'Tiempo',
    
            "NO ASIGNADO": 'Ninguno',
    
            PROVIDER: this.titlecase.transform((this.userService.modulesName?.provider || 'Proveedor')),
            CONTRACTOR: this.titlecase.transform((this.userService.modulesName?.contractor || 'Contratista')),
            SUPPORT: 'Soporte',
            ADMIN: 'Director',
            CLIENT: this.userService.modulesName?.client || 'Cliente',
            SUPERVISOR: 'Supervisor',
            AREA_LEADER: this.userService.modulesName?.area_leader || 'Líder de Área',
            ACTIVE: 'Activo',
            INACTIVE: 'Inactivo',
            RELEASED: 'Liberado',
            USER: "Usuario",
            RATING: "Valoración",
            "STATUS-CHANGE": "Cambio de Estado",
            TEXT: "Texto",
            "DYNAMIC-SELECT": "Selección",
            TAG: "Etiqueta",
            STATUS: "Estado",
            DATE: "Fecha",
            IMAGES: "Imágenes",
            NUMBER: "Numérico",
            LOG: "Actualizaciones",
            FILES: "Archivos",
            BID: "Licitación",
            LONGTEXT: "Texto Largo",
            NAME: "Nombre",
            EMAIL: "Correo",
            PHONE: "Teléfono",
            ADDRESS: "Dirección",
            INCOME: "Ingresos",
            DISCHARGE: "Egresos",
            REVISION: "Revisión",
            "N/A": 'Cliente',
            "CUBRE COSTO": "Propietario",
            "GARANTIA": "Garantia",
            "NA": "N/A",
            "AVAILABLE": "Disponible",
            "NOT AVAILABLE": "No Disponible",
            "ASSIGNED": "Asignado",
            "CREATED": "Nuevo",
            "STARTED": "Activa",
            "REQUEST": "Propuesta en revisión",
            "FINISHED": "Finalizado",
            "CLOSED": "Cerrado",
            "PENDING": "Pendiente",
            "REJECTED": "Rechazado",
            REJECTED_PROVIDER: 'Rechazado por participante',
            AUTHORIZED: 'Autorizado',
            "N/S": "N/A",
            "CURRENT": "Vigente",
            "NOCURRENT": "Finalizado",
            "CONTRACT": this.userService.modulesName?.contract || 'Contrato'
        },
        "BIDS":{
            "CREATED": "Nuevo",
            "STARTED": "Activa",
            "REQUEST": "Estimación en revisión",
            "FINISHED": "Cerrado",
            "CANCELLED": "Cancelado",
            "CLOSED": "Cerrado",
            "PENDING": "Estimación en revisión",
            "ACCEPTED": "Ganador",
            "REJECTED": "Cerrado",
            "REJECTED_PROVIDER": "Rechazaste la licitación"
        },
        "GRAPHIC":{
            "ACTIVE": 'Activos',
            "INACTIVE": 'Inactivos',
        }
        /*
        BIDDING STATUS 
        CREATED,  STARTED, CANCELLED, CLOSED, FINISHED

        BIDDING REQUEST STATUS
        CREATED, PENDING, REJECTED, ACCEPTED, CANCELLED, REJECTED_PROVIDER
        */
    }
    constructor(
        public userService: UserService,
        private titlecase: TitleCasePipe
    ) {}

    transform(value: string, module: string = "DEFAULT"): string {
        return value? this.lenguages[module.toUpperCase()][value.toUpperCase()] || value :"N/A" ;
    }
}

interface ILenguege{
    en?: any,
    es?: any,
}

